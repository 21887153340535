import { Index } from '@interacta-shared/util';
import { BoundedTipConfig } from '@modules/core/models/bounded-tip.model';

export type DiscoveryId =
    | 'bootcamp-sample'
    | 'dashboard-filters'
    | 'dashboard-workflow-action-bar'
    | 'dashboard-personalize-menu'
    | 'detail-attachments-comments'
    | 'detail-workflow-action-bar'
    | 'detail-attachments-async-upload'
    | 'comments-fullscreen'
    | 'dashboard-personalize-filters'
    | 'manage'
    | 'community-detail'
    | 'pin-dashboard-filters'
    | 'new-home'
    | 'admin-v2-area-creation'
    | 'admin-v2-business-unit-creation'
    | 'admin-v2-people'
    | 'admin-v2-roles'
    | 'admin-v2-workflow-template'
    | 'admin-v2-catalog'
    | 'systemTheme'
    | 'noLongerDarkBetaTheme'
    | 'insights-cross-community'
    | 'ai'
    | 'ai-attachments-threads';

export interface DiscoveryFeature {
    id: DiscoveryId;
    instance?: Index;
}

export const DiscoverIdWithVersion: Record<DiscoveryId, string | null> = {
    systemTheme: '3.2',
    noLongerDarkBetaTheme: '3.2',
    'admin-v2-area-creation': null,
    'admin-v2-business-unit-creation': null,
    'admin-v2-people': null,
    'admin-v2-roles': null,
    'admin-v2-workflow-template': null,
    'admin-v2-catalog': null,
    'bootcamp-sample': null,
    'comments-fullscreen': null,
    'community-detail': null,
    'dashboard-filters': null,
    'dashboard-personalize-filters': null,
    'dashboard-personalize-menu': null,
    'dashboard-workflow-action-bar': null,
    'detail-attachments-async-upload': null,
    'detail-attachments-comments': null,
    'detail-workflow-action-bar': null,
    'new-home': null,
    'pin-dashboard-filters': null,
    'insights-cross-community': null,
    manage: null,
    ai: null,
    'ai-attachments-threads': null,
};

export type DiscoveryTipConfig<T> = Omit<BoundedTipConfig<T>, 'closeBehavior'>;
