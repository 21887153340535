import { Injectable } from '@angular/core';
import { ConfigurationService } from '@interacta-shared/data-access-configuration';
import { TipAction, TipCloseAction } from '@interacta-shared/feature-tip';
import { BoundedTipService } from '@modules/core/services/bounded-tip.service';
import { LocalStorageService } from '@modules/core/services/local-storage.service';
import {
    SidebarSectionAdminId,
    SidebarSectionId,
} from '@modules/sidebar/models/sidebar.model';
import { EMPTY, Observable, Subject } from 'rxjs';
import {
    DiscoverIdWithVersion,
    DiscoveryFeature,
    DiscoveryId,
    DiscoveryTipConfig,
} from '../models/discovery.model';

type AllSidebarSectionIds = SidebarSectionId | SidebarSectionAdminId;

@Injectable({
    providedIn: 'root',
})
export class DiscoveryService {
    constructor(
        private tipService: BoundedTipService,
        private localStorageService: LocalStorageService,
        private configurationService: ConfigurationService,
    ) {}

    openTip<T>(
        feature: DiscoveryFeature,
        config: DiscoveryTipConfig<T>,
    ): Observable<TipAction<T> | TipCloseAction> {
        const key = this.getViewedKey(feature);
        const viewed = this.localStorageService.getEntry<boolean>(
            key,
            'ifLogged',
        );
        const canShowTipAfterVersionChecked =
            this.canShowTipThroughVersionCheck(feature.id);
        if (!viewed && canShowTipAfterVersionChecked) {
            const obs = new Subject<TipAction<T> | TipCloseAction>();
            this.tipService
                .openBoundedTip({
                    ...config,
                    closeBehavior: 'manual',
                })
                .subscribe({
                    next: (action) => {
                        this.localStorageService.setEntry(
                            key,
                            true,
                            'ifLogged',
                        );
                        obs.next(action);
                    },
                    error: (error) => obs.error(error),
                    complete: () => obs.complete(),
                });
            return obs;
        } else {
            return EMPTY;
        }
    }

    canShowTipThroughVersionCheck(featureId: DiscoveryId): boolean {
        const version = DiscoverIdWithVersion[featureId];
        const currentVersion =
            this.configurationService.getEnvironmentInfo()?.buildInfo.backend
                ?.version;

        return !version || !!currentVersion?.startsWith(version);
    }

    getNewSectionIds(): SidebarSectionId[] {
        const result: SidebarSectionId[] = [];
        const currentVersion =
            this.configurationService.getEnvironmentInfo()?.buildInfo.backend
                ?.version;
        //** [IISP-8671] Remove the commment to the code when u need to use the new section's badge
        //** eg.
        //
        // if (
        //    currentVersion &&
        //    currentVersion.startsWith('2.7') &&
        //    !this.getOpened('manage')
        // ) {
        //    result.push('manage');
        // }
        return result;
    }

    getNewAdminSectionIds(): SidebarSectionAdminId[] {
        const result: SidebarSectionAdminId[] = [];
        const currentVersion =
            this.configurationService.getEnvironmentInfo()?.buildInfo.backend
                ?.version;

        if (currentVersion && currentVersion.startsWith('3.2')) {
            if (!this.getOpened('people-admin')) {
                result.push('people-admin');
            }

            if (!this.getOpened('roles')) {
                result.push('roles');
            }
        }

        if (currentVersion && currentVersion.startsWith('3.4')) {
            if (!this.getOpened('workspace')) {
                result.push('workspace');
            }

            if (!this.getOpened('community')) {
                result.push('community');
            }
        }

        if (currentVersion && currentVersion.startsWith('3.5')) {
            if (!this.getOpened('workflow_template')) {
                result.push('workflow_template');
            }
            if (!this.getOpened('catalog')) {
                result.push('catalog');
            }
        }

        return result;
    }

    markSectionAsOpened(sidebarSectionId: AllSidebarSectionIds): void {
        if (!this.getOpened(sidebarSectionId)) {
            const key = this.getOpenedKey(sidebarSectionId);
            this.localStorageService.setEntry(key, true, 'ifLogged');
        }
    }

    private getViewedKey(feature: DiscoveryFeature): string {
        return `discovery:viewed:${feature.id}${
            feature.instance != null ? '(' + feature.instance + ')' : ''
        }`;
    }

    private getOpenedKey(sidebarSectionId: AllSidebarSectionIds): string {
        return `discovery:opened:${sidebarSectionId}`;
    }

    private getOpened(
        sidebarSectionId: AllSidebarSectionIds,
    ): boolean | undefined {
        const key = this.getOpenedKey(sidebarSectionId);
        return this.localStorageService.getEntry<boolean>(key, 'ifLogged');
    }
}
